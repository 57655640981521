import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import colors from 'constants/colors';
import { humanizeDate } from 'utils/time';
import { ItemDate, ItemLabel, ItemLabelSeparator } from './Timeline.css';

type HTimelineProps = {
  items: { id: string; label: string; date: Date; icon?: JSX.Element }[];
};

export const HTimeline = ({ items }: HTimelineProps) => {
  return (
    <Timeline
      sx={{
        '& .MuiTimelineItem-root:before': {
          flex: 0,
          padding: 0,
        },
        '& li.MuiTimelineItem-root': {
          minHeight: 0,
        },
      }}
    >
      {items
        .sort((a, b) => (a.date > b.date ? 1 : -1))
        .map((item, i) => (
          <TimelineItem key={item.id}>
            <TimelineSeparator>
              <TimelineDot
                style={{
                  boxShadow: 'none',
                  marginTop: '2px',
                  marginBottom: '2px',
                  width: '4px',
                  height: '4px',
                }}
              />
              {i < items.length - 1 ? (
                <TimelineConnector
                  style={{
                    marginTop: '5px',
                    marginBottom: '3px',
                    background: colors.gray200,
                    minHeight: '6px',
                    width: '1px',
                  }}
                />
              ) : null}
            </TimelineSeparator>
            <TimelineContent sx={{ py: '2px' }}>
              <ItemLabel>
                <span>{item.label}</span>
                <ItemLabelSeparator />
                <ItemDate>{humanizeDate(item.date)}</ItemDate>
              </ItemLabel>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};
